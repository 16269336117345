import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { GAME, GAMES } from '../../../constants';
import sellInstantlyCSGOArtwork from './images/artworks/sell-instantly-hero.jpg';
import sellInstantlyCSGOArtwork2x from './images/artworks/sell-instantly-hero@2x.jpg';
import sellInstantlyDOTA2Artwork from './images/logo_dota2.png';
import sellInstantlyDOTA2Artwork2x from './images/logo_dota2@2x.png';
import sellInstantlyTF2Artwork from './images/logo_tf2.svg';
import * as s from './Intro.module.css';
import {
  INSTANT_PROVIDERS,
  IntroButton,
  IntroDescription,
  IntroHeader,
  IntroMethods,
  IntroTitle,
  IntroWrapper,
  SkinwalletInstantLogo,
} from './IntroUtils';

const messages = defineMessages({
  headline: {
    id: 'intro.sellInstantly.headline',
    defaultMessage: `Evaluate, Choose, Sell. <url>Get Cash!</url>`,
  },
  buttonCaption: {
    id: 'intro.sellInstantly.ctaCaption',
    defaultMessage: 'get cash now',
  },
  titleCSGO: {
    id: 'intro.sellInstantly.title.CSGO',
    defaultMessage: `Real money from your\nCS:GO skins <bold>instantly</bold>`,
  },
  descriptionCSGO: {
    id: 'intro.sellInstantly.description.CSGO',
    defaultMessage: `Sell your CS:GO skins for real cash instantly and pay out to\nthe e-wallet of your choice. Enjoy the best instant prices!`,
  },
  titleDOTA2: {
    id: 'intro.sellInstantly.title.DOTA2',
    defaultMessage: `Real money from your\nDOTA 2 items <bold>instantly</bold>`,
  },
  descriptionDOTA2: {
    id: 'intro.sellInstantly.description.DOTA2',
    defaultMessage: `Sell your DOTA 2 Items for real cash instantly and pay out to\nthe e-wallet of your choice. Enjoy the best instant prices!`,
  },
  titleTF2: {
    id: 'intro.sellInstantly.title.TF2',
    defaultMessage: `Real money from your\nTeam Fortress 2 items\n<bold>instantly</bold>`,
  },
  descriptionTF2: {
    id: 'intro.sellInstantly.description.TF2',
    defaultMessage: `Sell your Team Fortress 2 Items for real cash instantly and pay out to\nthe e-wallet of your choice. Enjoy the best instant prices!`,
  },
});

const variant = {
  [GAME.CSGO]: {
    theme: s.csgo,
    title: messages.titleCSGO,
    description: messages.descriptionCSGO,
    url: '/panel/deposit',
    artwork: (
      <LazyLoadImage
        className={s.instantSellCSGOArtwork}
        src={sellInstantlyCSGOArtwork}
        srcSet={`${sellInstantlyCSGOArtwork2x} 2x`}
        alt="CS:GO"
        width={420}
        height={646}
      />
    ),
  },
  [GAME.DOTA2]: {
    theme: s.dota2,
    title: messages.titleDOTA2,
    description: messages.descriptionDOTA2,
    url: '/panel/deposit?inventory=dota2',
    artwork: (
      <LazyLoadImage
        className={s.artwork}
        src={sellInstantlyDOTA2Artwork}
        srcSet={`${sellInstantlyDOTA2Artwork2x} 2x`}
        alt="DOTA 2"
        width={327}
        height={64}
      />
    ),
  },
  [GAME.TF2]: {
    theme: s.tf2,
    title: messages.titleTF2,
    description: messages.descriptionTF2,
    url: '/panel/deposit?inventory=tf2',
    artwork: (
      <LazyLoadImage
        className={s.artwork}
        src={sellInstantlyTF2Artwork}
        alt="TF 2"
        width={340}
        height={72}
      />
    ),
  },
};

const IntroSellInstantly = ({ game }) => {
  const { formatMessage } = useIntl();

  const { theme, title, description, url, artwork } = variant[game];

  return (
    <IntroWrapper theme={theme}>
      <IntroHeader>
        <IntroTitle
          headline={formatMessage(messages.headline, {
            url: (value) => (
              <a href={url}>
                <strong>{value}</strong>
              </a>
            ),
          })}
          title={formatMessage(title, {
            bold: (value) => <strong>{value}</strong>,
          })}
          isInitial
        />
        <IntroDescription description={formatMessage(description)} />
        <IntroButton
          id="intro-cta-sell-instantly"
          caption={formatMessage(messages.buttonCaption)}
          href={url}
        />
      </IntroHeader>
      <IntroMethods
        methods={INSTANT_PROVIDERS}
        productLogo={<SkinwalletInstantLogo />}
      />
      {artwork}
    </IntroWrapper>
  );
};

IntroSellInstantly.propTypes = {
  game: PropTypes.oneOf(GAMES).isRequired,
};

export default IntroSellInstantly;
