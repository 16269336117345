import cx from 'classnames';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { GAME } from '../../constants';

import Section from '../Grid/Section/Section';

import CheckRegularIcon from './images/check-regular.inline.svg';
import ExternalLinkRegularIcon from './images/external-link-regular.inline.svg';
import WhiteLogo from './images/logo-white.inline.svg';
import Logo from './images/logo.inline.svg';
import PayeerLogo from './images/payeer.inline.svg';
import SteamLogo from './images/steam.inline.svg';
import TipaltiLogo from './images/tipalti.inline.svg';

import * as s from './Instant.module.css';

const messages = defineMessages({
  title: {
    id: 'instant.title',
    defaultMessage: `how to get instant cash\nfrom your steam inventory`,
  },
  link: {
    id: 'instant.link',
    defaultMessage: 'instant sale',
  },
  loginStepTitle: {
    id: 'instant.loginStep.title',
    defaultMessage: 'log in via steam',
  },
  loginStepDescriptionSkins: {
    id: 'instant.loginStep.description.skins',
    defaultMessage: `Add your Steam Trade URL to\nevaluate your skins collection.`,
  },
  loginStepDescriptionItems: {
    id: 'instant.loginStep.description.items',
    defaultMessage: `Add your Steam Trade URL to\nevaluate your items collection.`,
  },
  evaluateStepTitleSkins: {
    id: 'instant.evaluateStep.title.skins',
    defaultMessage: 'evaluate & choose skins',
  },
  evaluateStepTitleItems: {
    id: 'instant.evaluateStep.title.items',
    defaultMessage: 'evaluate & choose items',
  },
  evaluateStepDescriptionSkins: {
    id: 'instant.evaluateStep.description.skins',
    defaultMessage: `Select your skins from your\ninventory that you want to sell.`,
  },
  evaluateStepDescriptionItems: {
    id: 'instant.evaluateStep.description.items',
    defaultMessage: `Select your items from your\ninventory that you want to sell.`,
  },
  payoutStepTitle: {
    id: 'instant.payoutStep.title',
    defaultMessage: 'payout your money',
  },
  payoutStepDescriptionSkins: {
    id: 'instant.payoutStep.description.skins',
    defaultMessage: `Sell your skins and payout your money\neasily through multiple methods.`,
  },
  payoutStepDescriptionItems: {
    id: 'instant.payoutStep.description.items',
    defaultMessage: `Sell your items and payout your money\neasily through multiple methods.`,
  },
  payoutStepMore: {
    id: 'instant.payoutStep.more',
    defaultMessage: 'and more...',
  },
});

const steps = {
  [GAME.CSGO]: [
    {
      title: messages.loginStepTitle,
      description: messages.loginStepDescriptionSkins,
    },
    {
      title: messages.evaluateStepTitleSkins,
      description: messages.evaluateStepDescriptionSkins,
    },
    {
      title: messages.payoutStepTitle,
      description: messages.payoutStepDescriptionSkins,
    },
  ],
  [GAME.DOTA2]: [
    {
      title: messages.loginStepTitle,
      description: messages.loginStepDescriptionItems,
    },
    {
      title: messages.evaluateStepTitleItems,
      description: messages.evaluateStepDescriptionItems,
    },
    {
      title: messages.payoutStepTitle,
      description: messages.payoutStepDescriptionItems,
    },
  ],
  [GAME.TF2]: [
    {
      title: messages.loginStepTitle,
      description: messages.loginStepDescriptionItems,
    },
    {
      title: messages.evaluateStepTitleItems,
      description: messages.evaluateStepDescriptionItems,
    },
    {
      title: messages.payoutStepTitle,
      description: messages.payoutStepDescriptionItems,
    },
  ],
};

const Instant = ({ game }) => {
  const { formatMessage } = useIntl();
  const step = steps[game];

  return (
    <Section id="about-instant-section">
      <div className={s.headline}>
        <Logo className={s.logo} />
        <a href="/login?continue=/panel/deposit" className={s.desktopLink}>
          {formatMessage(messages.link)}
          <ExternalLinkRegularIcon />
        </a>
      </div>
      <h2 className={s.title}>{formatMessage(messages.title)}</h2>
      <ol className={s.entries}>
        <li className={s.entry}>
          <div className={s.step}>
            <h3 className={s.stepTitle}>{formatMessage(step[0].title)}</h3>
            <p className={s.stepDescription}>
              {formatMessage(step[0].description)}
            </p>
            <div className={s.stepExtras}>
              <SteamLogo />
            </div>
          </div>
        </li>
        <li className={s.entry}>
          <div className={s.step}>
            <h3 className={s.stepTitle}>{formatMessage(step[1].title)}</h3>
            <p className={s.stepDescription}>
              {formatMessage(step[1].description)}
            </p>
            <div className={s.stepExtras}>
              <WhiteLogo />
            </div>
          </div>
        </li>
        <li className={s.entry}>
          <div className={cx(s.step, s.lastStep)}>
            <h3 className={s.stepTitle}>
              <CheckRegularIcon />
              {formatMessage(step[2].title)}
            </h3>
            <p className={s.stepDescription}>
              {formatMessage(step[2].description)}
            </p>
            <div className={s.stepExtras}>
              <PayeerLogo />
              <TipaltiLogo />
              <span> {formatMessage(messages.payoutStepMore)}</span>
            </div>
          </div>
        </li>
      </ol>
      <a href="/login?continue=/panel/deposit" className={s.mobileLink}>
        {formatMessage(messages.link)}
        <ExternalLinkRegularIcon />
      </a>
    </Section>
  );
};

Instant.propTypes = {
  game: PropTypes.oneOf(Object.keys(GAME)).isRequired,
};

export default Instant;
