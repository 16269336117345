// extracted by mini-css-extract-plugin
export var desktopLink = "Market-module--desktopLink--OY9Qi Market-module--link--q1XGe";
export var entries = "Market-module--entries--7ab7n";
export var entry = "Market-module--entry--jTYZY";
export var entryDescription = "Market-module--entryDescription--T9B92";
export var entryTitle = "Market-module--entryTitle--QBKeo";
export var headline = "Market-module--headline--REwF3";
export var link = "Market-module--link--q1XGe";
export var logo = "Market-module--logo--5v4q8";
export var mobileLink = "Market-module--mobileLink--UHi5z Market-module--link--q1XGe";
export var title = "Market-module--title--6QCeh";