/* eslint-disable react/jsx-no-target-blank */

import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'gatsby-plugin-react-intl';

import TrustpilotLogo from './images/trustpilot.png';
import TrustpilotLogo2x from './images/trustpilot@2x.png';
import TrustpilotLogo3x from './images/trustpilot@3x.png';

import Section from '../Grid/Section';

import { siteLocaleDictionary } from '../../../config';

import * as s from './Trustpilot.module.css';

let scriptMounted = false;

function showTrustbox() {
  const trustbox = document.getElementById('trustbox');

  try {
    window.Trustpilot.loadFromElement(trustbox);
  } catch (err) {
    console.error(err);
  }
}

function mountTrustpilotScript() {
  const scriptTag = document.createElement('script');

  scriptTag.src =
    '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
  scriptTag.async = true;
  scriptTag.type = 'text/javascript';

  scriptTag.onerror = (errs) => console.error(errs);
  scriptTag.onload = () => showTrustbox();

  document.getElementsByTagName('head')[0].appendChild(scriptTag);

  scriptMounted = true;
}

function handleTrustpilotScript() {
  if (scriptMounted) {
    showTrustbox();
  } else {
    mountTrustpilotScript();
  }
}

const Trustpilot = () => {
  const { locale } = useIntl();
  useEffect(() => {
    if (window) {
      handleTrustpilotScript();
    }
  }, []);
  return (
    <>
      <Helmet
        link={[
          {
            rel: 'dns-prefetch',
            href: '//widget.trustpilot.com',
          },
        ]}
      />
      <Section>
        <div className={s.widgetWrapper}>
          <div
            id="trustbox"
            className={s.widget}
            data-locale={siteLocaleDictionary[locale]}
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="5c51a114fb592e00014d0e8d"
            data-style-height="140px"
            data-style-width="100%"
            data-theme="dark"
            data-stars="4,5"
            data-font-family="Barlow"
          >
            <a
              href="https://www.trustpilot.com/review/skinwallet.com"
              target="_blank"
              rel="noopener"
            >
              <img
                src={TrustpilotLogo}
                srcSet={`${TrustpilotLogo2x} 2x, ${TrustpilotLogo3x} 3x`}
                className={s.widgetLogo}
                alt="Trustpilot"
                width="86px"
                height="21px"
              />
            </a>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Trustpilot;
