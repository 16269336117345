import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import cx from 'classnames';
import gfm from 'remark-gfm';

import Section from '../Grid/Section/Section';
import * as s from './PopularQuestions.module.css';

const Link = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const markdownRenderers = {
  a: Link,
};

const messages = defineMessages({
  title: {
    id: 'popularQuestions.title',
    defaultMessage: 'popular questions',
  },
  subtitle: {
    id: 'popularQuestions.subtitle',
    defaultMessage:
      'Get to know us better. Read about us and find your answers.',
  },
});

const PopularQuestions = ({ questions }) => {
  const { formatMessage } = useIntl();

  return (
    <Section className={s.root}>
      <h2 className={s.title}>{formatMessage(messages.title)}</h2>
      <p className={s.subtitle}>{formatMessage(messages.subtitle)}</p>
      <ul className={s.questions}>
        {questions.map((question, index) => (
          <Fragment key={question.id}>
            <li id={question.id} className={s.question}>
              <h3 className={s.questionTitle}>{question.title}</h3>
              <ReactMarkdown
                className={cx('content', s.questionContent)}
                remarkPlugins={[gfm]}
                components={markdownRenderers}
              >
                {question.content}
              </ReactMarkdown>
            </li>
            {index % 2 !== 0 && index < questions.length - 1 && (
              <li className={s.divider}> </li>
            )}
          </Fragment>
        ))}
      </ul>
    </Section>
  );
};

PopularQuestions.propTypes = {
  questions: PropTypes.array.isRequired,
};

export default PopularQuestions;
