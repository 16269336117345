import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Container from '../Container';

import * as s from './Section.module.css';

const Section = ({ id, className, tagName, children }) => (
  <Container tagName={tagName} id={id} className={cx(s.root, className)}>
    {children}
  </Container>
);

Section.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  id: null,
  className: null,
  tagName: 'section',
};

export default Section;
