/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';

import Section from '../Grid/Section/Section';
import { GAME } from '../../constants';

import LongArrowRightLightIcon from './images/long-arrow-right-light.inline.svg';

import * as s from './Sell.module.css';

const messages = defineMessages({
  titleCSGO: {
    id: 'sell.title.CSGO',
    defaultMessage: 'how to sell cs:go skins?',
  },
  titleGAME: {
    id: 'sell.title.GAME',
    defaultMessage: 'how to sell { game } items?',
  },
  more: {
    id: 'sell.more',
    defaultMessage: 'learn more',
  },
  getNewTitle: {
    id: 'sell.getNew.title',
    defaultMessage: `How to get new\nCS:GO skins?`,
  },
  getNewContent: {
    id: 'sell.getNew.content',
    defaultMessage: `Before you can sell CSGO skins you have to somehow get them. In this article, we’re discussing <url>how to get new CSGO skins</url> and what are the best ways to do it.`,
  },
  worthTitle: {
    id: 'sell.worth.title',
    defaultMessage: `Check how much your\nCS:GO skins are worth`,
  },
  worthContent: {
    id: 'sell.worth.content',
    defaultMessage: `Many marketplaces show you your CSGO skins value, but are they correct in their evaluation? In this blog post we talk about methods of checking your <url>CSGO skin worth</url>.`,
  },
  bestWaysTitle: {
    id: 'sell.bestWays.title',
    defaultMessage: `Best ways to sell\nCS:GO skins`,
  },
  bestWaysContent: {
    id: 'sell.bestWays.content',
    defaultMessage: `What is the <url>best way to sell CSGO skins</url>? Depends on how many CSGO skins and how much time you have. Learn about four best ways to sell CSGO skins!`,
  },
  firstArticleTitle: {
    id: 'sell.firstArticle.title',
    defaultMessage: `What there is to know:\n{ game } items`,
  },
  firstArticleContent: {
    id: 'sell.firstArticle.content',
    defaultMessage:
      'All information regarding the rarity, acquisition, trading, and, of course, selling { game } items for real money collected in one place in a form of a convenient and easy guide. ',
  },
  secondArticleTitle: {
    id: 'sell.secondArticle.title',
    defaultMessage: `2019 guide to getting new\n{ game } items`,
  },
  secondArticleContent: {
    id: 'sell.secondArticle.content',
    defaultMessage:
      'If you want to sell { game } items, you need to have some in the first place. Learn how, in this complete guide on how to get new { game } items that you can sell for real money.',
  },
  thirdArticleTitle: {
    id: 'sell.thirdArticle.title',
    defaultMessage: `Best ways to sell\n{ game } items`,
  },
  thirdArticleContent: {
    id: 'sell.thirdArticle.content',
    defaultMessage:
      'We’re talking a closer look on all options in regard to selling { game } items and discuss all pros and cons of Steam Community Market, third-party markets and other ways of selling { game } items.',
  },
});

const content = {
  [GAME.CSGO]: {
    gameName: 'CS:GO',
    title: messages.titleCSGO,
    articles: [
      {
        name: 'how-to-get-new-csgo-skins',
        title: messages.getNewTitle,
        content: messages.getNewContent,
        url: '/csgo/how-to-get-new-csgo-skins/',
      },
      {
        name: 'how-much-is-my-csgo-skin-worth',
        title: messages.worthTitle,
        content: messages.worthContent,
        url: '/csgo/how-much-is-my-csgo-skin-worth/',
      },
      {
        name: 'best-places-to-sell-csgo-skins',
        title: messages.bestWaysTitle,
        content: messages.bestWaysContent,
        url: '/csgo/best-places-to-sell-csgo-skins/',
      },
    ],
  },
  [GAME.DOTA2]: {
    gameName: 'Dota 2',
    title: messages.titleGAME,
    articles: [
      {
        name: 'first-article',
        title: messages.firstArticleTitle,
        content: messages.firstArticleContent,
        url: null,
      },
      {
        name: 'second-article',
        title: messages.secondArticleTitle,
        content: messages.secondArticleContent,
        url: null,
      },
      {
        name: 'third-article',
        title: messages.thirdArticleTitle,
        content: messages.thirdArticleContent,
        url: null,
      },
    ],
  },
  [GAME.TF2]: {
    gameName: 'Team Fortress 2',
    title: messages.titleGAME,
    articles: [
      {
        name: 'first-article',
        title: messages.firstArticleTitle,
        content: messages.firstArticleContent,
        url: null,
      },
      {
        name: 'second-article',
        title: messages.secondArticleTitle,
        content: messages.secondArticleContent,
        url: null,
      },
      {
        name: 'third-article',
        title: messages.thirdArticleTitle,
        content: messages.thirdArticleContent,
        url: null,
      },
    ],
  },
};

const Sell = ({ game }) => {
  const { formatMessage } = useIntl();
  const { gameName, title, articles } = content[game];

  return (
    <Section>
      <h2 className={s.title}>{formatMessage(title, { game: gameName })}</h2>
      <ul className={s.articles}>
        {articles.map((article) => (
          <li key={article.name} className={s.article}>
            <h3 className={s.articleTitle}>
              {!article.url && formatMessage(article.title, { game: gameName })}
              {article.url && (
                <a href={article.url} target="_blank">
                  {formatMessage(article.title, { game: gameName })}
                </a>
              )}
            </h3>
            <p className={s.articleContent}>
              {formatMessage(article.content, {
                game: gameName,
                url: (value) => (
                  <a href={article.url} target="_blang">
                    {value}
                  </a>
                ),
              })}
            </p>
            {article.url && (
              <a href={article.url} className={s.more} target="_blank">
                {formatMessage(messages.more)}
                <LongArrowRightLightIcon />
              </a>
            )}
          </li>
        ))}
      </ul>
    </Section>
  );
};

Sell.propTypes = {
  game: PropTypes.oneOf(Object.keys(GAME)).isRequired,
};

export default Sell;
