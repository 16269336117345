import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as s from './Container.module.css';

const Container = ({ children, tagName, id, className }) => {
  const TagName = tagName;
  return (
    <TagName id={id} className={cx(s.root, className)}>
      {children}
    </TagName>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  tagName: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
};

Container.defaultProps = {
  tagName: 'div',
  id: null,
  className: null,
};

export default Container;
