// extracted by mini-css-extract-plugin
export var desktopLink = "Instant-module--desktopLink--ZX1Z1 Instant-module--link--7KaWQ";
export var entries = "Instant-module--entries--9eMPn";
export var entry = "Instant-module--entry--OmO5R";
export var headline = "Instant-module--headline--5Aol7";
export var lastStep = "Instant-module--lastStep--8CrDm";
export var link = "Instant-module--link--7KaWQ";
export var logo = "Instant-module--logo--h+lA7";
export var mobileLink = "Instant-module--mobileLink--Ukgx- Instant-module--link--7KaWQ";
export var step = "Instant-module--step--ZZP7+";
export var stepDescription = "Instant-module--stepDescription--TDiIT";
export var stepExtras = "Instant-module--stepExtras--mZ9pD";
export var stepTitle = "Instant-module--stepTitle--agdyn";
export var title = "Instant-module--title--j4S-t";