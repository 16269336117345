import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { PROVIDER } from '../../../constants';
import Container from '../../Grid/Container/Container';
import SkinwalletInstantLogoHorizontal from './images/skinwallet-instant-horizontal.inline.svg';
import SkinwalletInstantLogoVertical from './images/skinwallet-instant-vertical.inline.svg';
import SkinwalletMarketLogoHorizontal from './images/skinwallet-market-horizontal.inline.svg';
import SkinwalletMarketLogoVertical from './images/skinwallet-market-vertical.inline.svg';
import * as s from './Intro.module.css';

export const INSTANT_PROVIDERS = [
  PROVIDER.AMAZON_GIFT_CARDS,
  PROVIDER.VANILLA_VISA,
  PROVIDER.PAYEER,
  PROVIDER.TIPALTI,
  PROVIDER.PAYONEER,
  PROVIDER.AIRTM,
];

export const MARKET_SELL_PROVIDERS = [
  PROVIDER.AMAZON_GIFT_CARDS,
  PROVIDER.VANILLA_VISA,
  PROVIDER.PAYONEER,
  PROVIDER.VISA,
  PROVIDER.CONOTOXIA,
  PROVIDER.TETHER,
];

export const MARKET_PAY_PROVIDERS = [
  PROVIDER.MASTERCARD,
  PROVIDER.VISA,
  PROVIDER.UNION_PAY,
  PROVIDER.WEB_MONEY,
  PROVIDER.BITCOIN,
  PROVIDER.SKRILL,
];

export const SkinwalletInstantLogo = () => (
  <>
    <SkinwalletInstantLogoVertical className={s.verticalLogo} />
    <SkinwalletInstantLogoHorizontal className={s.horizontalLogo} />
  </>
);

export const SkinwalletMarketLogo = () => (
  <>
    <SkinwalletMarketLogoVertical className={s.verticalLogo} />
    <SkinwalletMarketLogoHorizontal className={s.horizontalLogo} />
  </>
);

export const IntroWrapper = ({ theme, children }) => {
  const rootClasses = cx(s.root, theme);

  return (
    <div className={rootClasses}>
      <Container className={s.container}>{children}</Container>
      <div className={s.fold} />
    </div>
  );
};

IntroWrapper.propTypes = {
  theme: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const IntroHeader = ({ children }) => (
  <header className={s.header}>
    <div className={s.content}>{children}</div>
  </header>
);

IntroHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export const IntroButton = ({ id, caption, href }) => (
  <a
    id={id}
    href={href}
    role="button"
    className={s.ctaButton}
    aria-label={caption}
  >
    <span>{caption}</span>
  </a>
);

IntroButton.propTypes = {
  id: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export const IntroTitle = ({ headline, title, isCenter, isInitial }) => {
  const TitleTag = isInitial ? 'h1' : 'h2';

  return (
    <TitleTag className={cx(s.title, { [s.isCenter]: isCenter })}>
      <small>{headline}</small>
      <span>{title}</span>
    </TitleTag>
  );
};

IntroTitle.propTypes = {
  headline: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  isInitial: PropTypes.bool,
  isCenter: PropTypes.bool,
};

IntroTitle.defaultProps = {
  isInitial: false,
  isCenter: false,
};

export const IntroDescription = ({ description }) => (
  <p className={s.description}>{description}</p>
);

IntroDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export const IntroMethods = ({ methods, productLogo }) => (
  <div className={s.methods}>
    <div className={s.product}>{productLogo}</div>
    <ul className={s.providers}>
      {methods.map((method) => (
        <li
          key={method.name}
          className={cx(s.provider, s[method.slug])}
          aria-label={method.name}
        >
          {method.logo}
        </li>
      ))}
    </ul>
  </div>
);

IntroMethods.propTypes = {
  methods: PropTypes.array.isRequired,
  productLogo: PropTypes.element.isRequired,
};
