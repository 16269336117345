import React from 'react';

import Container from '../Container/Container';

import * as s from './Divider.module.css';

export const Divider = () => <div className={s.divider} />;

export const SectionDivider = () => (
  <Container>
    <Divider />
  </Container>
);
