import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import sellOnMarketArtwork from './images/artworks/sell-on-market-hero.jpg';
import sellOnMarketArtwork2x from './images/artworks/sell-on-market-hero@2x.jpg';
import * as s from './Intro.module.css';
import {
  IntroButton,
  IntroDescription,
  IntroHeader,
  IntroMethods,
  IntroTitle,
  IntroWrapper,
  MARKET_SELL_PROVIDERS,
  SkinwalletMarketLogo,
} from './IntroUtils';

const messages = defineMessages({
  headline: {
    id: 'intro.sellOnMarket.headline',
    defaultMessage: 'your skins, your rules!',
  },
  title: {
    id: 'intro.sellOnMarket.title',
    defaultMessage: `take your skin trading\ngame to the next level`,
  },
  description: {
    id: 'intro.sellOnMarket.description',
    defaultMessage: `Sell your CS:GO skins on the marketplace for real money.\nName your price and connect with hundreds of traders.`,
  },
  buttonCaption: {
    id: 'intro.sellOnMarket.ctaCaption',
    defaultMessage: 'sell skins',
  },
});

const IntroSellOnMarket = () => {
  const { formatMessage } = useIntl();

  return (
    <IntroWrapper theme={s.marketSell}>
      <IntroHeader>
        <IntroTitle
          headline={formatMessage(messages.headline)}
          title={formatMessage(messages.title)}
        />
        <IntroDescription description={formatMessage(messages.description)} />
        <IntroButton
          id="intro-cta-sell-on-market"
          caption={formatMessage(messages.buttonCaption)}
          href="/market/sell"
        />
      </IntroHeader>
      <IntroMethods
        methods={MARKET_SELL_PROVIDERS}
        productLogo={<SkinwalletMarketLogo />}
      />
      <LazyLoadImage
        className={s.marketSellArtwork}
        src={sellOnMarketArtwork}
        srcSet={`${sellOnMarketArtwork2x} 2x`}
        alt="Sell on market"
        width={525}
        height={744}
      />
    </IntroWrapper>
  );
};

export default IntroSellOnMarket;
