import React from 'react';
import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';

import Section from '../Grid/Section';
import { GAME } from '../../constants';

import InstantIcon from './images/instant.inline.svg';
import SecuretIcon from './images/secure.inline.svg';
import SupportIcon from './images/support.inline.svg';

import * as s from './UniqueValuePropositions.module.css';

const messages = defineMessages({
  instantCashTitle: {
    id: 'uniqueValuePropositions.instantCash.title',
    defaultMessage: `instant cash for\nyour steam inventory`,
  },
  instantCashDescriptionCSGO: {
    id: 'uniqueValuePropositions.instantCash.description.CSGO',
    defaultMessage:
      'Sell your CS:GO skins on Skinwallet Instant in just a few minutes. Monetize unwanted CS:GO skins and pay out your earned cash to a secure e-wallet of your choice.',
  },
  instantCashDescriptionDOTA2: {
    id: 'uniqueValuePropositions.instantCash.description.DOTA2',
    defaultMessage:
      'Sell your Dota 2 items on Skinwallet Instant in just a few minutes. Monetize unwanted Dota 2 items and pay out your earned cash to a secure e-wallet of your choice.',
  },
  instantCashDescriptionTF2: {
    id: 'uniqueValuePropositions.instantCash.description.TF2',
    defaultMessage:
      'Sell your Team Fortress 2 items on Skinwallet Instant in just a few minutes. Monetize unwanted TF2 items and pay out your earned cash to a secure e-wallet of your choice.',
  },
  secureTitle: {
    id: 'uniqueValuePropositions.secure.title',
    defaultMessage: `100% secure & monitored\ntransactions`,
  },
  secureDescription: {
    id: 'uniqueValuePropositions.secure.description',
    defaultMessage:
      'Every transaction you make on Skinwallet is designed to be 100% safe. Follow our tested security guidelines built with expert traders to remain secure at all times.',
  },
  customerServiceTitle: {
    id: 'uniqueValuePropositions.customerService.title',
    defaultMessage: `friendly 24/7\ncustomer service`,
  },
  customerServiceDescription: {
    id: 'uniqueValuePropositions.customerService.description',
    defaultMessage:
      'Having any questions? Our friendly, attentive customer support team will answer all your questions to the utmost of your satisfaction quickly and efficiently.',
  },
});

const getInstantCashDescriptionByGame = (game) => {
  switch (game) {
    case GAME.CSGO:
      return messages.instantCashDescriptionCSGO;
    case GAME.DOTA2:
      return messages.instantCashDescriptionDOTA2;
    case GAME.TF2:
      return messages.instantCashDescriptionTF2;
    default:
      return null;
  }
};

const UniqueValuePropositions = ({ game }) => {
  const { formatMessage } = useIntl();
  const entries = [
    {
      id: 'instantCash',
      icon: <InstantIcon />,
      title: messages.instantCashTitle,
      description: getInstantCashDescriptionByGame(game),
    },
    {
      id: 'secure',
      icon: <SecuretIcon />,
      title: messages.secureTitle,
      description: messages.secureDescription,
    },
    {
      id: 'customerService',
      icon: <SupportIcon />,
      title: messages.customerServiceTitle,
      description: messages.customerServiceDescription,
    },
  ];

  return (
    <Section>
      <ul className={s.entries}>
        {entries.map((entry) => (
          <li key={entry.id} className={s.entry}>
            <div className={s.proposition}>
              <div className={s.propositionIcon}>{entry.icon}</div>
              <p className={s.propositionTitle}>{formatMessage(entry.title)}</p>
              <p className={s.propositionDescription}>
                {formatMessage(entry.description)}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </Section>
  );
};

UniqueValuePropositions.propTypes = {
  game: PropTypes.oneOf(Object.keys(GAME)).isRequired,
};

export default UniqueValuePropositions;
